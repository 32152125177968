import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import { sort } from "./helpers/sort";
import "./statsTable.css";

const StatsTable = data => {
  const [currentData, setCurrentData] = useState([]);
  useEffect(() => {
    const reworkedData = data.data.edges.map(key => {
      return key.node;
    });
    setCurrentData(sort("Win", "ASC", reworkedData));
  }, [data]);
  const handleClick = (col, order) => {
    const reworkedData = data.data.edges.map(key => {
      return key.node;
    });
    setCurrentData(sort(col, order, reworkedData));
  };

  return (
      <>
        <div className='top-scroll'>
          <div className='top-scroll-content'> </div>
        </div>
        <div className='stats'>
          <div className='stats-controls'>
            <div className='stats-controls__title'>
              <span>Player name</span>
            </div>
            <div className='stats-controls__title'>
              <span>Games played</span>
            </div>
            <div className='stats-controls__title'>
              <span>Total wins</span>
            </div>
            <div className='stats-controls__title'>
              <span>Total losses</span>
            </div>
            <div className='stats-controls__title'>
              <span>Win rate</span>
            </div>
            <div className='stats-controls__title'>
              <span>Tournaments entered</span>
            </div>
            <div className='stats-controls__title'>
              <span>Top 8</span>
            </div>
            <div className='stats-controls__title'>
              <span>Top 3</span>
            </div>
            <div className='stats-controls__title'>
              <span>1st</span>
            </div>
          </div>
          <div className="stats-controls__arrows">
            <div className='stats-controls__arrows-buttons' />
            <div className='stats-controls__arrows-buttons'>
              <span
                  role="button"
                  className='stats-controls__button asc'
                  onClick={() => handleClick("Games_Played", "ASC")}
                  onKeyPress={() => handleClick("Games_Played", "ASC")}
              />
              <span
                  role="button"
                  className='stats-controls__button desc'
                  onClick={() => handleClick("Games_Played", "DESC")}
                onKeyPress={() => handleClick("Games_Played", "DESC")}
              />
            </div>
            <div className='stats-controls__arrows-buttons'>
              <span
                  role="button"
                  className='stats-controls__button asc'
                  onClick={() => handleClick("Win", "ASC")}
                  onKeyPress={() => handleClick("Win", "ASC")}
              />
              <span
                  role="button"
                  className='stats-controls__button desc'
                  onClick={() => handleClick("Win", "DESC")}
                  onKeyPress={() => handleClick("Win", "DESC")}
              />
            </div>
            <div className='stats-controls__arrows-buttons'>
              <span
                  role="button"
                  className="stats-controls__button asc"
                  onClick={() => handleClick("Lost", "ASC")}
                  onKeyPress={() => handleClick("Lost", "ASC")}
              />
              <span
                  role="button"
                  className="stats-controls__button desc"
                  onClick={() => handleClick("Lost", "DESC")}
                  onKeyPress={() => handleClick("Lost", "DESC")}
              />
            </div>
            <div className='stats-controls__arrows-buttons'>
              <span
                  role="button"
                  className="stats-controls__button asc"
                  onClick={() => handleClick("Rate", "ASC")}
                  onKeyPress={() => handleClick("Rate", "ASC")}
              />
              <span
                  role="button"
                  className="stats-controls__button desc"
                  onClick={() => handleClick("Rate", "DESC")}
                  onKeyPress={() => handleClick("Rate", "DESC")}
              />
            </div>
            <div className='stats-controls__arrows-buttons'>
              <span
                  className="stats-controls__button asc"
                  onClick={() => handleClick("Tournaments_entered", "ASC")}
                  onKeyPress={() => handleClick("Tournaments_entered", "ASC")}
              />
              <span
                  className="stats-controls__button desc"
                  onClick={() => handleClick("Tournaments_entered", "DESC")}
                  onKeyPress={() => handleClick("Tournaments_entered", "DESC")}
              />
            </div>
            <div className='stats-controls__arrows-buttons'>
              <span
                  className="stats-controls__button asc"
                  onClick={() => handleClick("Top_8", "ASC")}
                  onKeyPress={() => handleClick("Top_8", "ASC")}
              />
              <span
                  role="button"
                  className="stats-controls__button desc"
                  onClick={() => handleClick("Top_8", "DESC")}
                  onKeyPress={() => handleClick("Top_8", "DESC")}
              />
            </div>
            <div className='stats-controls__arrows-buttons'>
              <span
                  role="button"
                  className='stats-controls__button asc'
                  onClick={() => handleClick("Top_3", "ASC")}
                  onKeyPress={() => handleClick("Top_3", "ASC")}
              />
              <span
                  role="button"
                  className='stats-controls__button desc'
                  onClick={() => handleClick("Top_3", "DESC")}
                  onKeyPress={() => handleClick("Top_3", "DESC")}
              />
            </div>
            <div className='stats-controls__arrows-buttons'>
              <span
                  role="button"
                  className='stats-controls__button asc'
                  onClick={() => handleClick("First_place", "ASC")}
                  onKeyPress={() => handleClick("First_place", "ASC")}
              />
              <span
                  role="button"
                  className='stats-controls__button desc'
                  onClick={() => handleClick("First_place", "DESC")}
                  onKeyPress={() => handleClick("First_place", "DESC")}
              />
            </div>
          </div>
          <div className='stats-data'>
            {currentData.map(player => {
              return(
                <div className='stats-data__row' key={player.Name}>
                  <div>{player.Name}</div>
                  <div>{player.Games_Played}</div>
                  <div>{player.Win}</div>
                  <div>{player.Lost}</div>
                  <div>{player.Rate}%</div>
                  <div>{player.Tournaments_entered}</div>
                  <div>{player.Top_8}</div>
                  <div>{player.Top_3}</div>
                  <div>{player.First_place}</div>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
};

StatsTable.propTypes = {
  data: PropTypes.object.isRequired,
};

export default StatsTable;
