import React, {useState} from "react";
import { StaticQuery, graphql } from "gatsby";
import StatsTable from "../components/statsTable";

import Layout from "../components/layout";
import "./tournament.css";
import SeasonControl from "../components/SeasonControl";

const Stats = () => {
    const [season, setSeason] = useState(2);

    return (
      <Layout>
        <StaticQuery
          query={graphql`
              query {
                season1:allStatisticsXlsxSheet1 {
                  edges {
                    node {
                      Name
                      Games_Played
                      Win
                      Lost
                      Rate
                      Tournaments_entered
                      Top_8
                      Top_3
                      First_place
                    }
                  }
                }
                season2:allStatistics2XlsxSheet1 {
                  edges {
                    node {
                      Name
                      Games_Played
                      Win
                      Lost
                      Rate
                      Tournaments_entered
                      Top_8
                      Top_3
                      First_place
                    }
                  }
                }
              }
            `}
          render={data => {
              return (
              <div>
                <h1>STATISTICS</h1>
                  <SeasonControl onSeasonChange={number => setSeason(number)} active={season} seasons={Object.keys(data).length} />
                  <div className='body'>
                  <div className="info">Last updated: February 25, 2020</div>
                  <StatsTable data={data[`season${season}`]} />
                </div>
              </div>
            );
          }}
        />
      </Layout>
);
};

export default Stats;
