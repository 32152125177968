import React from "react"
import PropTypes from "prop-types";

const SeasonControl = ({ onSeasonChange, active, seasons }) => {
    let buttons = [];

    for (let i = 1; i < seasons + 1; i++) {
        buttons.push(<button key={i} disabled={i === active} onClick={() => onSeasonChange(i)} className={`${i === active ? "active" : ""}`}>Season {i}</button>);
    }
    return (
        <div className="season-control">
            {buttons}
        </div>
    );
};

SeasonControl.propTypes = {
    onSeasonChange: PropTypes.func.isRequired,
    active: PropTypes.number.isRequired,
    seasons: PropTypes.number.isRequired,
};

export default SeasonControl;